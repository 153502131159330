import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Button, Center, Heading, Text } from "@chakra-ui/react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Container from "../components/ui/Container";

function About() {
  return (
    <Layout>
      <Seo
        title="WHY BITDECODERS FOR YOUR BUSINESS | KNOW MORE ABOUT US"
        meta={[
          {
            name: "keywords",
            content:
              "website development company, website developer near me, website designing company, ecommerce website developer, web developer companies, indian web design company, website design and development company, web development agency, best web development company, web design and development services, ecommerce website development company, web development company near me, ecommerce web development company, ecommerce web design company, custom web design services, website development company near me, web design and development company, best web designer, top website development company, custom web development company, best website developers, web design & development company, website development agency, webdesign company, best ecommerce website development company, business website development, web and mobile app development companies, web design & development services, ecommerce website designing company, top web design company, website design & development company, mobile app designing company, best web designing companies, best indian web design company, web development companies services",
          },
          {
            name: "description",
            content:
              "BITDECODERS is the team name of WELLSOUL PRIVATE LIMITED. This team works in the field of Programming Code. Currently, we are dealing in WEBSITE DESIGN & DEVELOPMENT, e-Commerce DESIGN & DEVELOPMENT, and MOBILE APP DESIGN & DEVELOPMENT. We specialize in Amazon Web Services, Google Cloud & Microsoft Azure, and others.",
          },
        ]}
      />

      <Box position="relative">
        <StaticImage
          src="../images/unsplash_m_HRfLhgABo.png"
          alt="Bitdecoders About Banner Image"
          layout="fullWidth"
        />

        <Center
          position="absolute"
          right={{ base: "-1", md: "-2", lg: "-4" }}
          top={{ base: "0", md: "10", lg: "20" }}
          width={{ base: "2/3", md: "1/3", lg: "25%" }}
          p={{ base: "3", md: "4", lg: "5" }}
          color="white"
          opacity="0.95"
        >
          <Heading
            as="h1"
            textAlign="right"
            fontSize={{ base: "lg", md: "3xl", lg: "4xl" }}
            fontWeight="bold"
            padding="4"
          >
            <Text color="white">MANAGE YOUR </Text>
            <Text color="red.500">BUSINESS </Text>
            <Text color="white">WITH US</Text>
          </Heading>
        </Center>
      </Box>

      <Container fluid>
        <div className="text-2xl text-center pt-10 pb-4">
          <p>
            <span className="text-black">
              <b>ABOUT</b>
            </span>{" "}
            <span className="text-red-500">
              <b>US</b>
            </span>
          </p>
        </div>
        <div className="text-1xl text-center  ml-4 mr-4">
          <p>
            <b>BITDECODERS</b> is the team name of{" "}
            <b>WELLSOUL PRIVATE LIMITED.</b> This team works in the field of
            Programming Code. Currently, we are dealing in{" "}
            <b>
              WEBSITE DESIGN &amp; DEVELOPMENT, e-Commerce DESIGN &amp;
              DEVELOPMENT, and MOBILE APP DESIGN &amp; DEVELOPMENT.
            </b>{" "}
            We specialize in{" "}
            <b>Amazon Web Services, Google Cloud &amp; Microsoft Azure</b> and
            others. We give your business or portfolio a powerful website and
            online presence which not only caters to your specific business
            needs but helps you build better clientele and improves your
            visibility online. Our team works in complete coordination and
            cooperation to accomplish every project well on time without
            compromising on the quality front.
          </p>
        </div>

        <div>
          <div className="text-2xl text-center mt-8 p-4">
            <p>
              <span className="text-black font-bold">WHY</span>{" "}
              <span className="text-red-500">
                <b>US</b>
              </span>
            </p>
          </div>
          <div className="text-1xl text-center  ml-3 mr-3">
            <p>
              Our work speaks for our quality. We are committed to producing
              outstanding results for every project to satiate our worldwide
              clients on all fronts. Our web designing and development
              professionals have excellent skills and good industry exposure
              that help them to give every website a unique online identity.
            </p>
          </div>
        </div>
        <div className="text-2xl font-bold text-center p-4 mt-8">
          Let&apos;s Talk About What We Can Build Together
        </div>
        <div className="text-1xl text-center ml-4 mr-4">
          <p>
            Whatever may be your requirement - be it a simple website design, a
            complex data driven web application development, an ecommerce
            website, a native or cross platform mobile app development - we have
            a solution for you.
          </p>
        </div>
        <div className=" text-center mt-10 mb-16">
          <Button as={Link} href="/contact-us/" colorScheme="red">
            Contact Us Now For a Free Quote!
          </Button>
        </div>
      </Container>
    </Layout>
  );
}

export default About;
